import store from '@/state/store.js';
export default {
  name: "debt",
  data() {
    return {
      transactions: [],
      showingDebt: false
    };
  },
  methods: {},
  mounted() {
    store.dispatch('wallet/displayDebt').then(() => {
      this.showingDebt = true;
    }).catch(err => {
      if (err.response.status === 404) {
        this.showingDebt = false;
      }
    });
  },
  computed: {
    debt() {
      return store.getters['wallet/debtAmount'];
    }
  }
};