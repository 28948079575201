<template>
  <ul class="__load_wallet__list">
    <li>
      <div class="__card_icon">
        <button v-if="data.top_up_by" class="__card_icon_icon">
          <img src="@/assets/Arrow - Down.svg" />
        </button>
        <button v-if="data.bundle_by" class="purchase__bundle__btn">
          <img
            width="10px"
            height="10px"
            src="@/assets/Arrow - Up.svg"
            alt="" />
        </button>
      </div>
      <div class="__card_wallet_load contains-load">
        <p class="__top__text">
          {{ data.top_up_by ? "Load Wallet" : "Bundle purchased" }}
        </p>
        <p class="__date">
          {{ moment(data.created_at).format("l") }}
        </p>
      </div>
      <div class="__card__amount contains-anount">
        <p class="__amount contains-amount">{{ processAmount(data.amount) }}</p>
      </div>
    </li>
  </ul>
</template>

<script>
  import Storage from "../../../../services/storage";
  var storage = new Storage();
  export default {
    name: "transaction-history-card",
    props: {
      data: {
        required: false,
      },
    },
    data() {
      return {};
    },
    methods: {
      processAmount(amount) {
        const parsedAmount = Number(amount);
        const country = storage.getSavedState("currentUser").country;
        const isFrancophoneCountry = country === "CI" || country === "SN";
        return parsedAmount.toLocaleString(
          isFrancophoneCountry ? "fr-CI" : "en-US",
          {
            style: "currency",
            currency: isFrancophoneCountry ? "CFA" : "GHS",
          }
        );
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import url("../../../../styles/wallet/transactionhistory.scss");

  .contains-amount {
    // width: min-content;
    min-width: 50px;
    max-width: max-content;
    margin: 0 auto;
  }
</style>
