<template>
  <div class="scrollable">
    <div class="row">
      <!-- 0244 123 706 -->
      <div class="col-sm-12 col-md-7 col-lg-8 col-xl-8">
        <WalletTab></WalletTab>
        <div v-if="windowWidth <= 768">
          <TransactionHistoryHeader />
        </div>
        <div
          id="account-settings"
          class="mnotify-card"
          style="padding: 0px !important">
          <WalletHeder />
          <div class="mnotify-card-body">
            <div class="__wallet_navigation_header">
              <!-- <div class="__wallet__navigation">
                      <button :class="[pageType == 'retail' ? 'btn_active' : '']" @click="hancleComponentChange('retail')">
                          Retail
                      </button>
                      <button :class="[pageType == 'enterprise' ? 'btn_active' : '']" @click="hancleComponentChange('enterprice')">
                          Enterprise
                      </button>
                    </div> -->
            </div>
            <div class="__main_wallet_component">
              <transition name="fade">
                <component v-bind:is="currentTabComponent"></component>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4">
        <div
          id="account-settings"
          class="mnotify-card"
          style="padding: 0px !important">
          <div v-if="windowWidth > 768">
            <TransactionHistoryHeader />
          </div>
          <div class="mnotify-card-body">
            <TransactionHistory />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import WalletHeder from "./walletHeader.vue";
  import Retail from "./retail.vue";
  import Enterprise from "./enterprise.vue";
  import TransactionHistory from "./TransactionHistory.vue";
  import TransactionHistoryHeader from "./transactionsHistoryHeader";
  import WalletTab from "./WalletTab.vue";

  export default {
    name: "wallet",
    components: {
      WalletHeder,
      Retail,
      Enterprise,
      TransactionHistory,
      TransactionHistoryHeader,
      WalletTab,
    },
    data() {
      return {
        currentTabComponent: Retail,
        pageType: "retail",
        windowWidth: window.innerWidth,
      };
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);
      });
      // Analytics
      this.$mixpanel.track("Wallet Page");
      if (this.$store.getters["auth/currentUser"].country === "CI") {
        this.$mixpanelFranco.track("Wallet Page");
      }
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
    },
    methods: {
      onResize() {
        this.windowWidth = window.innerWidth;
      },
      hancleComponentChange(type) {
        if (type == "retail") {
          this.pageType = "retail";
          return (this.currentTabComponent = Retail);
        }
        this.pageType = "enterprise";
        return (this.currentTabComponent = Enterprise);
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import url("../../../styles/wallet/__buycredit.scss");

  @media screen and (max-width: 530px) {
    .__wallet__navigation {
      width: 100%;

      button {
        width: 50%;
      }
    }
  }

  @media screen and (max-width: 820px) {
    body,
    html {
      overflow: auto;
    }
  }

  .scrollable {
    overflow-y: hidden;
    height: 88vh;
    overflow-x: hidden;
    /* padding-bottom: calc(20vh - 100px); */
  }
  @media screen and (max-width: 769px) {
    .scrollable {
      height: 100vh;
      overflow: auto;
      padding: 0.5rem;
    }
    .scrollable::-webkit-scrollbar {
      width: 4px;
    }
  }
</style>
