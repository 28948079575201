var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [Array.isArray(_vm.allHistory) && _vm.allHistory.length > 0 ? _c('div', {}, _vm._l(_vm.allHistory, function (data, index) {
    return _c('HistoryCard', {
      key: index,
      attrs: {
        "data": data,
        "type": "load"
      }
    });
  }), 1) : _c('NoDataComponent', {
    attrs: {
      "message": _vm.message
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };