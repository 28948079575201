var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('ul', {
    staticClass: "fixed-headers"
  }, [_c('li', [_vm._v("Payable Amount:" + _vm._s(_vm.debt.payable_amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'GHC'
  })))]), _c('li', [_vm._v("Paid Amount:" + _vm._s(_vm.debt.paid_amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'GHC'
  })))]), _c('li', [_vm._v("Amount Due :" + _vm._s(_vm.debt.due.toLocaleString('en-US', {
    style: 'currency',
    currency: 'GHC'
  })))]), _vm.debt.status == 0 ? _c('li', [_vm._v("Status:"), _c('span', {
    staticClass: "due"
  }, [_vm._v("Due ")])]) : _vm._e(), _vm.debt.status == 1 ? _c('li', [_vm._v("Status: "), _c('span', {
    staticClass: "completed"
  }, [_vm._v("Completed")])]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };