var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "__wallet__header"
  }, [_c('div', {
    staticClass: "__sms_"
  }, [_c('h3', {
    staticClass: "__header__"
  }, [_vm._v(" " + _vm._s(_vm.sms_voice) + " ")]), _c('p', {
    staticClass: "__text notranslate"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.wallet.subtitle')) + " ")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };