import $ from 'jquery';
import WalletCard from './walletCard.vue';
import enterpriseCardData from '../../../helpers/enterprise.js';
import Storage from '@/services/storage.js';
export default {
  name: 'buy-bundle',
  components: {
    WalletCard
  },
  data() {
    return {
      userSelectedData: {},
      success: '',
      error: '',
      loading: false,
      inputFormData: {
        amount: '',
        messages: ''
      },
      rules: {
        amount: [{
          required: true,
          message: 'Amount is required',
          trigger: 'blur'
        }],
        messages: [{
          required: true,
          message: 'Amount is required',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    enterpriseData() {
      const allEnterpriceData = enterpriseCardData;
      const storage = new Storage();
      let isCoporate = storage.getSavedState('isCorporate');
      if (!isCoporate) return enterpriseCardData;
      return allEnterpriceData.filter(retail => retail.amount >= 100);
    }
  },
  mounted() {
    store.dispatch('wallet/accountBalance');
  },
  methods: {
    choseBundle(val) {
      this.userSelectedData = val;
    },
    showBuyFlexibleBundle() {
      $('#flexiblepayment').modal('show');
    },
    buy(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.promoLoading = true;
          this.proMoSuccess = null;
          this.proMoError = null;
          store.dispatch('auth/register', this.formData).then(response => {
            if (response) {
              this.proMoSuccess = response.data.message;
              this.promoLoading = false;
            }
          }).catch(err => {
            this.promoLoading = false;
            if (err.response.data == undefined) {
              this.proMoError = "An error occured try again.";
            }
            if (err.response && err.response.status === 404) {
              this.proMoError = err.response.data.data;
            }
          });
        } else {
          return false;
        }
      });
    },
    hideModal() {
      $('#flexiblepayment').modal('hide');
    }
  }
};