var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [Array.isArray(_vm.loadWallet) && _vm.loadWallet.length > 0 ? _c('div', {}, [_vm._l(_vm.loadWallet, function (data) {
    return _c('HistoryCard', {
      key: data.id,
      attrs: {
        "data": data,
        "type": "load"
      }
    });
  }), _c('router-link', {
    staticClass: "view-all-link",
    attrs: {
      "to": "/wallet/wallet-history"
    }
  }, [_c('div', {
    staticClass: "view-all-container"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    }
  }, [_vm._v(_vm._s(_vm.$t("dashboard.wallet.view_all")))])], 1)])], 2) : _c('NoDataComponent', {
    attrs: {
      "message": _vm.message
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };