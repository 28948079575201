import HistoryCard from '../transactionhistory/transactionHIstoryCard.vue';
import store from '@/state/store.js';
import NoDataComponent from "./NodataComponent.vue";
export default {
  name: "bundle-purchased",
  components: {
    HistoryCard,
    NoDataComponent
  },
  data() {
    return {
      message: this.$t('dashboard.wallet.no_bundle')
    };
  },
  computed: {
    bundlePurchase() {
      let allHistory = store.getters['wallet/allHistory'];
      //  filter allHistory where ref_id does not exist
      let filteredHistoryBundle = allHistory.filter(item => {
        return item.hasOwnProperty("ref_id") == false;
      });
      return filteredHistoryBundle.slice(0, 6);
    }
  }
};