import { render, staticRenderFns } from "./TransactionHistory.vue?vue&type=template&id=59ca67bf&scoped=true"
import script from "./TransactionHistory.vue?vue&type=script&lang=js"
export * from "./TransactionHistory.vue?vue&type=script&lang=js"
import style0 from "./TransactionHistory.vue?vue&type=style&index=0&id=59ca67bf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ca67bf",
  null
  
)

export default component.exports