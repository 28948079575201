import HistoryCard from '../transactionhistory/transactionHIstoryCard.vue';
import NoDataComponent from './NodataComponent.vue';
import store from '@/state/store.js';
export default {
  name: "load-wallet",
  components: {
    HistoryCard,
    NoDataComponent
  },
  data() {
    return {
      message: this.$t('dashboard.wallet.no_wallet_history')
    };
  },
  beforeMount() {
    store.dispatch('wallet/walletBundleHistory');
  },
  computed: {
    allHistory() {
      return store.getters['wallet/allHistory'];
    }
  }
};