var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mnotify-card"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center my-3"
  }, [_c('p', {
    staticClass: "text-ceneter",
    staticStyle: {
      "font-size": "13px"
    }
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };