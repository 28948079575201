var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "__transaction_history"
  }, [_c('div', {
    staticClass: "to__nav"
  }, [_c('div', {
    staticClass: "__navigation_content"
  }, [_c('button', {
    staticClass: "__nav_btn",
    class: [_vm.activeHistory == 'all' ? 'btn_active' : ''],
    on: {
      "click": function ($event) {
        return _vm.toggleCurrentHistory('all');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('dashboard.wallet.all')))]), _vm.isShowingDebt ? _c('button', {
    staticClass: "__nav_btn",
    class: [_vm.activeHistory == 'debt' ? 'btn_active' : ''],
    showDebt: _vm.showDebt,
    on: {
      "click": function ($event) {
        return _vm.toggleCurrentHistory('debt');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('dashboard.wallet.debt')))]) : _vm._e(), _c('button', {
    staticClass: "__nav_btn",
    class: [_vm.activeHistory == 'loadwallet' ? 'btn_active' : ''],
    on: {
      "click": function ($event) {
        return _vm.toggleCurrentHistory('loadwallet');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('dashboard.wallet.laod_wallets')))]), _c('button', {
    staticClass: "__nav_btn",
    class: [_vm.activeHistory == 'bundlepurchase' ? 'btn_active' : ''],
    on: {
      "click": function ($event) {
        return _vm.toggleCurrentHistory('bundlepurchase');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('dashboard.wallet.bundle-purchase')))])])]), _c('div', {
    staticClass: "__transactions__histories"
  }, [_c('transition', [_c(_vm.currentHistory, {
    tag: "component"
  })], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };