import logintType from '../../../helpers/loginType';
export default {
  name: 'wallet-header',
  data() {
    return {
      currentView: 'buy_bundle'
    };
  },
  computed: {
    sms_voice() {
      return logintType() == 'sms' ? this.$t('dashboard.wallet.sms_bundles') : this.$t('dashboard.wallet.voice_bundles');
    }
  }
};