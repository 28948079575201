<template>
  <div class="promo_active __wallet_card">
    <div>
      <p style="font-size: 28px; font-weight: bold">Voice Calculator</p>
    </div>
    <div>
      <el-form
        ref="ruleForm"
        :rules="rules"
        :model="calculatorForm"
        @submit.prevent.native="checkEnter"
        label-position="top">
        <div>
          <el-form-item prop="num_recipients" label="Number of Recipients">
            <el-input-number
              :controls="false"
              :placeholder="$t('dashboard.wallet.enter_your_amount')"
              v-model="calculatorForm.num_recipients"
              :max="100000" />
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="audio_length" label="Audio length in seconds">
            <el-input-number
              :controls="false"
              :placeholder="$t('dashboard.wallet.enter_your_amount')"
              :max="100000"
              v-model="calculatorForm.audio_length" />
          </el-form-item>
        </div>
        <div>
          <el-form-item label="Total Cost">
            <p style="font-weight: bold; font-size: large; margin: 0">
              {{ amountNeeded }}
            </p>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "voiceAmountChecker",
    props: {
      rate: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        rules: {
          num_recipients: [
            {
              required: true,
              message: "Please enter the number of recipients",
              trigger: "blur",
            },
            {
              type: "number",
              message: "Please enter a valid number",
              trigger: "blur",
            },
          ],
          audio_length: [
            {
              required: true,
              message: "Please enter the audio length",
              trigger: "blur",
            },
            {
              type: "number",
              message: "Please enter a valid number",
              trigger: "blur",
            },
          ],
        },
        calculatorForm: {
          num_recipients: "0",
          audio_length: "",
        },
      };
    },
    computed: {
      amountNeeded() {
        if (
          isNaN(this.calculatorForm.num_recipients) ||
          isNaN(this.calculatorForm.audio_length)
        ) {
          return "GHC 0.00";
        }

        // Convert rate per minute to rate per second (assuming audio_length is in seconds)
        const ratePerSecond = parseFloat(this.rate) / 60;

        // Calculate total cost
        const totalCost =
          this.calculatorForm.num_recipients *
          this.calculatorForm.audio_length *
          ratePerSecond;

        return totalCost.toLocaleString("en-US", {
          style: "currency",
          currency: "GHC",
        });
      },
    },
  };
</script>

<style scoped>
  .el-form-item >>> .el-input-number {
    width: 100%;
    text-align: left;
  }
  .el-form-item >>> label {
    margin-block: -0.5rem;
  }
</style>
