import HistoryCard from "../transactionhistory/transactionHIstoryCard.vue";
import NoDataComponent from "./NodataComponent.vue";
import store from "@/state/store.js";
export default {
  name: "load-wallet",
  components: {
    HistoryCard,
    NoDataComponent
  },
  data() {
    return {
      message: this.$t("dashboard.wallet.no_wallet_deposit")
    };
  },
  computed: {
    loadWallet() {
      let loadWallet = store.getters["wallet/depositBundleHistory"];
      //  filter allHistory where ref_id does not exist for load wallet
      let filteredHistory = loadWallet.filter(item => {
        return item.hasOwnProperty("ref_id");
      });
      return filteredHistory.slice(0, 6);
    }
  }
};