import All from './transactionhistory/all.vue';
import LoadWallet from './transactionhistory/loadwallets';
import BundlePurchase from './transactionhistory/bundlepurchase.vue';
import debt from './transactionhistory/debt.vue';
import store from '@/state/store.js';
export default {
  name: 'transaction-history',
  components: {
    All,
    LoadWallet,
    BundlePurchase,
    debt
  },
  data() {
    return {
      currentHistory: All,
      activeHistory: 'all',
      showDebt: false
    };
  },
  mounted() {
    store.dispatch('wallet/purchaseBundleHistory');
    store.dispatch('wallet/depositHistory');
    store.dispatch('wallet/displayDebt');
  },
  methods: {
    toggleCurrentHistory(type) {
      if (type === 'all') {
        this.activeHistory = 'all';
        return this.currentHistory = All;
      } else if (type === 'loadwallet') {
        this.activeHistory = 'loadwallet';
        return this.currentHistory = LoadWallet;
      } else if (type === 'bundlepurchase') {
        this.activeHistory = 'bundlepurchase';
        return this.currentHistory = BundlePurchase;
      } else {
        this.activeHistory = 'debt';
        return this.currentHistory = debt;
      }
    },
    showdebt() {
      store.dispatch('wallet/dispalyDebt');
    }
  },
  mounted() {
    store.dispatch('wallet/displayDebt').then(() => {
      this.showDebt = true;
    }).catch(err => {
      if (err.response.status === 404) {
        this.showDebt = false;
      }
    });
  },
  computed: {
    isShowingDebt() {
      return this.showDebt;
    }
  }
};